<template>
  <div
    v-if="browserName.includes('Firefox') || !to"
    @click="handleContainerClick"
    ref="tableTr"
    :class="[`tr-table-state-${state}`, {'un-hover-table': unHover, 'table-row': !unHover ,'selected': data, 'is-expand': maxHeight != '0px', 'activeEdit': activeEdit, 'hoverFlat': $parent.hoverFlat}]"
  >
    <td
      v-on:click.stop="clicktr"
      style="pointer-events: none; z-index: -1;"
      v-if="$parent.multiple"
      :class="$parent.orders? '' : 'border-0'"
      :style="$parent.orders ? `color: ${color};` : ''"
    >
      <div class="table-checkbox">
        <vs-checkbox
          v-on:click.stop
          v-if="$parent.multiple"
          :checked="isSelected"
          size="small"
          @change="handleCheckbox"
        />
      </div>
      <vs-icon v-if="$slots.expand">keyboard_arrow_down</vs-icon>
    </td>
    <slot></slot>
  </div>

  <router-link
    v-else
    ref="tableTr"
    :class="[`tr-table-state-${state}`, {'un-hover-table': unHover, 'table-row': !unHover ,'selected': data, 'is-expand': maxHeight != '0px', 'activeEdit': activeEdit, 'hoverFlat': $parent.hoverFlat}]"
    :to="to ? to : {query: $route.query}"
  >
    <td
      v-on:click.stop="clicktr"
      style="pointer-events: none; z-index: -1;"
      v-if="$parent.multiple"
      :class="$parent.orders? '' : 'border-0'"
      :style="$parent.orders ? `color: ${color};` : ''"
    >
      <div class="table-checkbox">
        <vs-checkbox
          v-on:click.stop
          v-if="$parent.multiple"
          :checked="isSelected"
          size="small"
          @change="handleCheckbox"
        />
      </div>
      <vs-icon v-if="$slots.expand">keyboard_arrow_down</vs-icon>
    </td>
    <slot></slot>
  </router-link>
</template>

<script>
/* eslint-disable */
export default {
  name: 'VsTr',
  props:{
    unHover: {
      type: Boolean
    },
    to: {
      type: Object
    },
    color: {
      type: String
    },
    state:{
      type:String,
      default: null
    },
    data: {
      default: null
    },
  },
  data:()=>({
    colspan:0,
    expanded: false,
    maxHeight:'0px',
    activeEdit: false,
    browserName: ''
  }),
  computed:{
    styleExpand () {
      return {
        maxHeight: this.maxHeight
      }
    },
    getColspanExpand () {
      let lengthx = this.$parent.$refs.colgroup.querySelectorAll('th').length
      return lengthx
    },
    isSelected(){
      if(this.$parent.multiple && this.$parent.value) {
        return this.data ? this.$parent.value.some(
              (item) => JSON.stringify(item) === JSON.stringify(this.data)
            ) : false
      } else {
        return this.data ? this.$parent.value == this.data : false
      }
    }
  },
  watch: {
    '$parent.datax'() {
      this.collapseExpandedData()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.colspan = this.$parent.$refs.thead.querySelectorAll('th').length
      if (this.$slots.expand) {
        this.colspan++
      }
    })
  },
  created() {
    if(this.$slots.expand) this.$parent.hasExpadableData = true
    this.browserName = navigator.userAgent
  },
  methods:{
    handleContainerClick(event) {
      if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'BUTTON') {
        this.$router.push(
          this.to ? {name: this.to.name, params: this.to.params} : {query: this.$route.query}
        ).catch(() => {})
      }
    },
    handleCheckbox() {
      this.$parent.handleCheckbox(this.data)
    },
    insertAfter(e,i){
      if(e.nextSibling){
        e.parentNode.insertBefore(i,e.nextSibling);
      } else {
        e.parentNode.appendChild(i);
      }
    },
    clicktr (evt) {
      this.$parent.clicktr(this.data, true)
    },
    collapseExpandedData() {
      if(this.expanded){
        const tr = this.$refs.tableTr
        tr.parentNode.removeChild(tr.nextSibling)
        tr.classList.remove('tr-expandedx')
        this.expanded = false
      }
    }
  }
}
</script>

<style lang="scss">
div.table-checkbox {
  width: 100%; 
  z-index: 999999999; 
  pointer-events: all;
  .con-vs-checkbox {
    display: flex;
    justify-content: center !important;
  }
}
.un-hover-table {
  display: table-row !important;
  z-index: 999 !important;
  -webkit-transition: all 500ms ease !important;
  transition: all 500ms ease !important;
}
.un-hover-table:hover {
  cursor: pointer;
  background-color:#DCEEFF70;
  animation-name: row;
  position: relative;
}
.table-row {
  z-index: 999;
  -webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}
.table-row:hover {
  cursor: pointer;
  -webkit-transform: translateY(-5px);
	transform: translateY(-5px);
  background-color:#DCEEFF70;
  animation-name: row;
  position: relative;
}
</style>